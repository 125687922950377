<template>
    <tr>
        <td>
            <table>
                <tbody>
                    <tr>
                        <td class="final-cell">
                            <a href="javascript:void(0);" @click="() => {}">
                                <font-awesome-icon class="handle" :icon="['fas', 'arrows-alt']"/>
                            </a>
                            <a href="javascript:void(0);"
                            @click="isOpen = !isOpen"
                            > {{ row.group_name }}
                            <font-awesome-icon v-if="!isOpen" :icon="['fa', 'chevron-down']"/>
                            <font-awesome-icon v-else :icon="['fa', 'chevron-up']"/>
                            </a>
                        </td>
                    </tr>
                    <tr v-show="isOpen">
                        <td>
                            <table class="tertiary-table">
                                <draggable
                                :list="row.data"
                                tag="tbody"
                                handle=".handle">
                                    <researchtable-subrow
                                    v-for="data in row.data"
                                    :key="data.id"
                                    :rowdata="data"
                                    :cols="cols"
                                    :ecodata="ecodata.find(el => el.data_id === data.data_id)"
                                    ></researchtable-subrow>
                                </draggable>
                            </table>
                        </td>
                        
                    </tr>
                </tbody>
            </table>
        </td>
    </tr>
</template>

<script>
import Draggable from 'vuedraggable';
import ResearchtableSubrow from './ResearchtableSubrow.vue';

export default {
    props: ["row", "cols", "ecodata"],
    components: {
        Draggable,
        ResearchtableSubrow
    },
    data() {
        return {
            isOpen: true
        }
    }
}
</script>